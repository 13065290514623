import { render, staticRenderFns } from "./AppHeader.vue?vue&type=template&id=ba21cd68&scoped=true&"
import script from "./AppHeader.vue?vue&type=script&lang=js&"
export * from "./AppHeader.vue?vue&type=script&lang=js&"
import style0 from "./AppHeader.vue?vue&type=style&index=0&id=ba21cd68&lang=SCSS&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba21cd68",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EbookButton: require('/data/www/nvpa.org/nvpa/nuxt/components/ebook/Button.vue').default,NewsBar: require('/data/www/nvpa.org/nvpa/nuxt/components/news/Bar.vue').default})
