//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import downloadEbook from '~/graphql/mutations/downloadEbook.gql';

export default {
  data: ({$auth}) => ({
    formData: {
      firstname: $auth.user?.voornaam,
      lastname: $auth.user?.achternaam,
      email: $auth.user?.e_mailadres,
      phone: $auth.user?.telefoonnummer,
    },
  }),
  methods: {
    close() {
      this.$emit('close');
    },
    submit(data) {
      if (this.downloading) {
        return;
      }

      this.downloading = true;

      this.$apollo.mutate({
        mutation: downloadEbook,
        variables: data,
      }).then(({data}) => {
        this.linkSource = 'data:application/pdf;base64,' + data.downloadEbook;
        this.downloadLink = document.createElement('a');

        this.datetime = new Date();
        this.fileName = 'Het NVPA E-book.pdf';

        this.downloadLink.href = this.linkSource;
        this.downloadLink.download = this.fileName;
        this.downloadLink.click();
        this.downloading = false;
      });

      this.close();
    },
  },
};
