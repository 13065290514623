
















export default {
  data: () => ({
    ebookModalOpen: false,
  }),
  methods: {
    downloadEbook() {
      this.ebookModalOpen = true;
    },
  },
};
